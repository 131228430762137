import { ColorKey } from 'app/styles/colors'
import get from 'lodash/get'
import React, { PropsWithChildren, ReactEventHandler } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { getStylesFromProps, ISizable } from 'ui/Common/util/Sizable'

/**
 * Box components props
 */
interface BoxProps extends ISizable {
  /**
   * Inline styles
   */
  style?: React.CSSProperties
  /**
   * Background color - color key from the styleguide pallet can be passed also
   */
  bg?: ColorKey | CSSProperties['color']
  /**
   * Border radius
   */
  br?: number | string
  /**
   * "Strength" of box shadow form the styleguide applied to the elemeny
   * TODO:: add when elevation is added to the styleguide
   */
  elevation?: number
  /**
   * Display style property
   *
   * @default "block"
   */
  d?: React.CSSProperties['display']
  /**
   * Position style property
   */
  position?: React.CSSProperties['position']
  /**
   * Click callback
   */
  onClick?: ReactEventHandler
  /**
   * Mouse over callback
   */
  onMouseOver?: React.MouseEventHandler
  /**
   * Mouse out callback
   */
  onMouseOut?: React.MouseEventHandler
}

/**
 * A subset of the Box props that are passed to inner styled component
 */
type InnerBoxProps = Pick<BoxProps, 'bg' | 'br' | 'd' | 'position'>

/**
 * SC wrapper for the Box component
 */
const _Box = styled.div<InnerBoxProps>`
  ${(p) => p.d && `display: ${p.d};`}
  ${(p) => p.position && `position: ${p.position};`}

  ${(p) => p.bg && `background-color: ${get(p.theme.colors, p.bg, p.bg)};`}
  ${(p) =>
    p.br && `border-radius: ${typeof p.br === 'number' ? `${p.br}px` : p.br};`}
`

/**
 * Box is a basic building block for all UI elements and layouts.
 */
const Box: React.FC<PropsWithChildren<BoxProps>> = (props) => {
  const style: React.CSSProperties = {
    ...getStylesFromProps(props),
    ...props.style,
  }

  return <_Box {...props} style={style} />
}

// Box defaults
Box.defaultProps = {
  d: 'block',
}

Box.displayName = 'MELD (Box)'

export default Box
