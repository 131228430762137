// ---------- detect UA

export function isAndroid(): boolean {
  return window.navigator.userAgent.match(/Android/i) != null
}

export function isBlackBerry(): boolean {
  return window.navigator.userAgent.match(/BlackBerry/i) != null
}

export function isIos(): boolean {
  return window.navigator.userAgent.match(/iPhone|iPad|iPod/i) != null
}

export function isOperaMini(): boolean {
  return window.navigator.userAgent.match(/Opera Mini/i) != null
}

export function isIeMobile(): boolean {
  return window.navigator.userAgent.match(/IEMobile/i) != null
}

export function isMobile(): boolean {
  return (
    isAndroid() || isBlackBerry() || isIos() || isOperaMini() || isIeMobile()
  )
}
