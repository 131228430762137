import React from 'react'

export const LaunchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill || '#FFF'}
    {...props}
  >
    <g fill={props.fill || '#FD003D'} fillRule="nonzero">
      <path d="M21.756 2.247a.838.838 0 0 0-.637-.246C9.366 2.614 5.024 13.084 4.982 13.19a.838.838 0 0 0 .184.905l4.74 4.74c.239.238.597.31.909.182.105-.043 10.518-4.433 11.184-16.131a.838.838 0 0 0-.243-.639zm-6.841 9.208a1.676 1.676 0 1 1-2.37-2.369 1.676 1.676 0 0 1 2.37 2.37zM2.933 17.68a2.383 2.383 0 1 1 3.37 3.37C5.371 21.981 2 22 2 22s0-3.39.933-4.32zM10.678 3.854a7.466 7.466 0 0 0-6.565 2.08 7.611 7.611 0 0 0-1.16 1.509.838.838 0 0 0 .13 1.018l1.667 1.667a22.306 22.306 0 0 1 5.928-6.274zM20.147 13.323a7.466 7.466 0 0 1-2.081 6.565 7.611 7.611 0 0 1-1.508 1.16.838.838 0 0 1-1.018-.13l-1.668-1.667a22.306 22.306 0 0 0 6.275-5.928z" />
    </g>
  </svg>
)
