import styled from 'styled-components'
import {
  largeBreakPointMediaCondition,
  MINIMAL_CONTENT_WIDTH,
} from 'ui/Common/components/Grid'

export const Page = styled.div`
  padding: 24px 20px 0 20px;
  margin-left: 96px;
  min-width: ${MINIMAL_CONTENT_WIDTH}px;

  @media (${largeBreakPointMediaCondition}) {
    padding: 24px 24px 0 24px;
  }
`

export const TitleContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.xl}px;
  margin-bottom: ${(p) => p.theme.spacing.xxl}px;

  color: ${(p) => p.theme.colors.grey.grey10};
  font-size: ${(p) => p.theme.spacing.xl}px;
  font-weight: 700;
  line-height: 1;
`
