import React from 'react'

export const WalletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M7.834 3.667h11.11v2.778H7.835z" />
      <path d="M19.639 7.833H5.75a.696.696 0 010-1.389h.694V5.056H5.75c-1.15 0-2.083.932-2.083 2.083v10.417a2.778 2.778 0 002.777 2.777H19.64a.695.695 0 00.694-.694V8.528a.695.695 0 00-.694-.695zm-3.472 7.64a1.389 1.389 0 11-.001-2.778 1.389 1.389 0 010 2.777z" />
    </g>
  </svg>
)
