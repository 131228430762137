import { useEffect, useState } from 'react'
import * as BrowserUtils from 'ui/Common/util/browserUtils'

/** Interface that describes runtime browser env info. */
export type IBrowserEnv = {
  isAndroid: () => boolean
  isBlackBerry: () => boolean
  isIos: () => boolean
  isOpera: () => boolean
  isIeMobile: () => boolean
  isMobile: () => boolean
}

export function createBrowserEnv(): IBrowserEnv {
  return {
    isAndroid: () => {
      return BrowserUtils.isAndroid()
    },

    isBlackBerry: () => {
      return BrowserUtils.isBlackBerry()
    },

    isIos: () => {
      return BrowserUtils.isIos()
    },

    isOpera: () => {
      return BrowserUtils.isOperaMini()
    },

    isIeMobile: () => {
      return BrowserUtils.isIeMobile()
    },

    isMobile: () => {
      return BrowserUtils.isMobile()
    },
  }
}

/** Retrieve browser env information and return in a convenient object. */
export function useBrowserEnv() {
  const [browserEnv, setBrowserEnv] = useState<IBrowserEnv>()

  useEffect(() => {
    // this touches `window` obj which is only available in browser so we have to use it in useEffect (because of Nextjs)
    setBrowserEnv(createBrowserEnv())
  }, [])

  return browserEnv
}
