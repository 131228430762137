import MobilePlaceholder from './MobilePlaceholder'
import * as S from './styles'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { Sidebar } from 'ui/App/components/Sidebar'
import { useBrowserEnv } from 'ui/Common/util/hooks/useBrowserEnv'

type LayoutProps = {
  children?: ReactNode
  countdown?: boolean
}

export default function Layout({ children, countdown }: LayoutProps) {
  const router = useRouter()
  const browserEnv = useBrowserEnv()

  // pathname is absolute (starts with /) so use second item
  const activeItem = router.pathname.split('/')[1]

  // browserEnv is undefined at first so we'll show nothing until it is created to avoid flashing content
  return (
    <>
      {browserEnv && !browserEnv.isMobile() && (
        <>
          <Sidebar countdown={countdown} activeItem={activeItem} />
          <S.Page>
            <main>{children}</main>
          </S.Page>
        </>
      )}

      {browserEnv?.isMobile() && <MobilePlaceholder />}
    </>
  )
}
