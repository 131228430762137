import * as S from './styles'
import Link from 'next/link'
import React, { ReactNode } from 'react'
import { isAkamonEnabled } from 'ui/Common/config'
import { BridgeIcon } from 'ui/Icons/components/BridgeIcon'
import { LaunchIcon } from 'ui/Icons/components/LaunchIcon'
import MeldLogo from 'ui/Icons/components/MeldLogo'
import { StakeIcon } from 'ui/Icons/components/StakeIcon'
import { WalletIcon } from 'ui/Icons/components/WalletIcon'

// import { DashboardIcon } from 'ui/Icons/components/DashboardIcon'
// import { LendIcon } from 'ui/Icons/components/LendIcon'
// import { BorrowIcon } from 'ui/Icons/components/BorrowIcon'
// import { MintIcon } from 'ui/Icons/components/MintIcon'
// import { NotificationIcon } from 'ui/Icons/components/NotificationIcon'
// import { SettingsIcon } from 'ui/Icons/components/SettingsIcon'

interface SidebarIconButtonProps {
  icon: ReactNode
  name: string
  active?: boolean
  value: string
}

function SidebarButton({ icon, active, value, name }: SidebarIconButtonProps) {
  return (
    <Link passHref href={`/${value}`}>
      <S.Button className={active ? 'activeItem' : ''} title={name}>
        <div className="icon">{icon}</div>
      </S.Button>
    </Link>
  )
}

type Item = { name: string; value: string; icon: ReactNode }

/**
 * Renders MELD sidebar. Sidebar contains navigation links, and serves as a menu.
 * Sidebar will highlight active item and render links to other pages.
 */
export function Sidebar({
  activeItem,
  countdown,
}: {
  activeItem: string
  countdown?: boolean
}) {
  // !!! REMOVE WITH COUNTDOWN
  const _items = countdown
    ? {
        middle: [
          { name: 'Countdown', value: 'countdown', icon: <LaunchIcon /> },
        ],
        bottom: [],
      }
    : ({
        middle: [
          // { name: 'Dashboard', value: 'dashboard', icon: <DashboardIcon /> },
          // { name: 'Lend', value: 'lend', icon: <LendIcon /> },
          // { name: 'Borrow', value: 'borrow', icon: <BorrowIcon /> },
          { name: 'Stake MELD', value: 'stake', icon: <StakeIcon /> },
          // { name: 'Mint', value: 'mint', icon: <MintIcon /> },
          { name: 'Wallet', value: 'wallet', icon: <WalletIcon /> },
          ...(isAkamonEnabled
            ? [
                {
                  name: 'Akamon Gateway',
                  value: 'akamon',
                  icon: <BridgeIcon />,
                },
              ]
            : []),
        ],
        bottom: [
          // {
          //   name: 'Notifications',
          //   value: 'notifications',
          //   icon: <NotificationIcon />,
          // },
          // { name: 'Settings', value: 'settings', icon: <SettingsIcon /> },
        ],
      } as { middle: Item[]; bottom: Item[] })

  return (
    <S.SidebarWrapper>
      <S.LogoSection>
        <MeldLogo width={40} height={40} />
      </S.LogoSection>

      <S.NavSection>
        {_items.middle.map(({ name, value, icon }) => (
          <SidebarButton
            key={value}
            value={value}
            name={name}
            icon={icon}
            active={value === activeItem}
          />
        ))}
      </S.NavSection>

      <S.SettingsSection>
        {_items.bottom.map(({ icon, name, value }) => (
          <SidebarButton key={value} name={name} value={value} icon={icon} />
        ))}
      </S.SettingsSection>
    </S.SidebarWrapper>
  )
}
