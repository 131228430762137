import React from 'react'
import Box from 'ui/Common/components/Box'
import Text from 'ui/Common/components/Text'

export default function MobilePlaceholder() {
  return (
    <Box
      d="flex"
      w="100%"
      h="100vh"
      bg="white.white100"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <video width={180} autoPlay loop muted playsInline>
        <source src="/video/meld-loader.mp4" type="video/mp4" />
      </video>
      <Box w={375}>
        <Text mt={32} mb={16} block color="#121826" variant="heading.3">
          Only the desktop version is supported for now.
        </Text>
        <Text color="#6c727f" variant="paragraph.3">
          Mobile version is coming soon.
        </Text>
      </Box>
    </Box>
  )
}
