import * as React from 'react'

function BridgeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-24 -420) translate(8 291) translate(0 113) translate(16 16) translate(1 2)"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      >
        <path d="M16.5818182 0L22.6909091 6.10909091 16.5818182 12.2181818 16.5818182 7.85454545 9.6 7.85454545 9.6 4.36363636 16.5818182 4.36363636z" />
        <path d="M6.10909091 19.2L0 13.0909091 6.10909091 6.98181818 6.10909091 11.3454545 13.0909091 11.3454545 13.0909091 14.8363636 6.10909091 14.8363636z" />
      </g>
    </svg>
  )
}

export { BridgeIcon }
