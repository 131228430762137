import styled from 'styled-components'

export const MINIMAL_CONTENT_WIDTH = 1248
export const LARGE_CONTENT_WIDTH = 1548
export const LARGE_BREAKPOINT = 1680

// FIXME rewrite this to use `max-width` like everything else
export const largeBreakPointMediaCondition = `min-width: ${
  LARGE_BREAKPOINT + 1
}px`

const GRID_BASIS_BASE = 84
const GRID_BASIS_LARGER = 96

const GRID_BASE_GAP = 20
const GRID_LARGER_GAP = 24

/**
 * Parent for all other Grid components, usually one Grid component per page. Children should be Row components.
 * Grid consists of 12 columns with gap between columns. Grid is fixed and has two defined widths.
 * On other widths Grid should be centered on the screen between sidebar and screen edge.
 */
export const Grid = styled.div`
  margin: 0 auto;
  min-width: ${MINIMAL_CONTENT_WIDTH}px;
  max-width: ${MINIMAL_CONTENT_WIDTH}px;

  @media (${largeBreakPointMediaCondition}) {
    min-width: ${LARGE_CONTENT_WIDTH}px;
    max-width: ${LARGE_CONTENT_WIDTH}px;
  }
`

/**
 * Marks single row in the grid. Contains one or multiple Column components.
 * Directs alignment of columns and takes care of half gaps before first and after last columns.
 */
export const Row = styled.div`
  width: ${12 * GRID_BASIS_BASE + 11 * GRID_BASE_GAP}px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;

  @media (${largeBreakPointMediaCondition}) {
    gap: 24px;
    width: ${12 * GRID_BASIS_LARGER + 11 * GRID_LARGER_GAP}px;
  }
`

/**
 * Column component places its children in the grid. Takes props which dictate placement.
 *
 * `base` defines how many columns should component take on base width. If not provided, component will take all available space (equivalent to 12).
 *
 * `larger` defines how many columns should component take on larger width. If not provided, `base` will be evaluated and the last fallback is full available width (equivalent to 12).
 *
 * `offset` defines how many columns should be left empty before component is placed in the grid. If not provided component will be placed on the grid start.
 *
 * `largerOffset` defines how many columns should be left empty on larger widths. If not provided `offset` will be evaluated.
 */
export const Column = styled.div<{
  base?: number
  larger?: number
  offset?: number
  largerOffset?: number
}>`
  ${(p) => {
    const baseColumnsWidth = (p.base || 12) * GRID_BASIS_BASE
    const LargerColumnsWidth = (p.larger || p.base || 12) * GRID_BASIS_LARGER

    const baseColumnsGapsSpan = ((p.base || 12) - 1) * GRID_BASE_GAP
    const largerColumnsGapsSpan =
      ((p.larger || p.base || 12) - 1) * GRID_LARGER_GAP

    const baseWidth = baseColumnsWidth + baseColumnsGapsSpan
    const largerWidth = LargerColumnsWidth + largerColumnsGapsSpan

    const baseOffsetColumnsWidth = (p.offset || 0) * GRID_BASIS_BASE
    const largerOffsetColumnsWidth =
      (p.largerOffset || p.offset || 0) * GRID_BASIS_LARGER

    const baseOffsetGaps = (p.offset || 0) * GRID_BASE_GAP
    const largerOffsetGaps = (p.largerOffset || p.offset || 0) * GRID_LARGER_GAP

    const baseOffset = baseOffsetColumnsWidth + baseOffsetGaps
    const largerOffset = largerOffsetColumnsWidth + largerOffsetGaps

    return `
        min-width: ${baseWidth}px;
        max-width: ${baseWidth}px;

        ${baseOffset > 0 ? `margin-left: ${baseOffset}px` : ''};

        @media (${largeBreakPointMediaCondition}) {
            min-width: ${largerWidth}px;
            max-width: ${largerWidth}px;

            ${largerOffset ? `margin-left: ${largerOffset}px` : ''};
        }
    `
  }}
`
