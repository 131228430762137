import styled from 'styled-components'
import { largeBreakPointMediaCondition } from 'ui/Common/components/Grid'

export const SidebarWrapper = styled.aside`
  height: 100vh;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${(p) => p.theme.elements.sidebar.backgroundColor};
  display: flex;
  flex-flow: column nowrap;
  padding-top: 24px;
  width: 72px;

  @media (${largeBreakPointMediaCondition}) {
    width: 96px;
    padding-top: 36px;
  }
`

export const Button = styled.button`
  display: block;
  outline: none;
  border: none;
  padding: 16px;
  transition: background-color 0.15s ease-in-out, fill 0.15s ease-in-out;
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 56px;
  width: 56px;

  .icon {
    width: 24px;
    height: 24px;
  }

  &.activeItem {
    fill: ${(p) => p.theme.colors.red.red6};
    background-color: ${(p) => p.theme.colors.grey.grey3};
  }

  :hover {
    background-color: ${(p) => p.theme.colors.grey.grey2};
  }

  :active {
    background-color: ${(p) => p.theme.colors.grey.grey3};
    fill: ${(p) => p.theme.colors.red.red6};
  }
`

export const LogoSection = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (${largeBreakPointMediaCondition}) {
    width: 48px;
    height: 48px;
  }
`

export const NavSection = styled.div`
  flex: 1 0;
  /* negative margin will offset logo and it's padding -> this will ensure icons are centered */
  margin: -64px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > * + * {
    margin-top: 2px;
  }
`

export const SettingsSection = styled.div`
  position: relative;
  z-index: 2;
`
