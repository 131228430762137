import * as React from 'react'
import { useUID } from 'react-uid'

function MeldLogo(props: React.SVGProps<SVGSVGElement>) {
  const idA = useUID()
  const idB = useUID()
  const idC = useUID()
  const idD = useUID()

  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          x1="9.686%"
          y1="73.431%"
          x2="43.126%"
          y2="53.999%"
          id={idA}
        >
          <stop stopColor="#CB1A4F" offset="0%" />
          <stop stopColor="#E51B44" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="44.066%"
          y1="56.774%"
          x2="57.141%"
          y2="42.582%"
          id={idB}
        >
          <stop stopColor="#E51B44" stopOpacity={0} offset="0%" />
          <stop stopColor="#E51B44" stopOpacity={0.049} offset="4.6%" />
          <stop stopColor="#E51B44" stopOpacity={0.178} offset="12.7%" />
          <stop stopColor="#E51B44" stopOpacity={0.33} offset="20.6%" />
          <stop stopColor="#E51B44" offset="52.6%" />
        </linearGradient>
        <linearGradient
          x1="43.991%"
          y1="56.797%"
          x2="57.178%"
          y2="42.582%"
          id={idC}
        >
          <stop stopColor="#E51B44" stopOpacity={0} offset="0%" />
          <stop stopColor="#E51B44" stopOpacity={0.049} offset="4.6%" />
          <stop stopColor="#E51B44" stopOpacity={0.178} offset="12.7%" />
          <stop stopColor="#E51B44" stopOpacity={0.33} offset="20.6%" />
          <stop stopColor="#E51B44" offset="52.6%" />
        </linearGradient>
        <linearGradient
          x1="12.7%"
          y1="20.517%"
          x2="82.2%"
          y2="75.608%"
          id={idD}
        >
          <stop stopColor="#D33651" stopOpacity={0} offset="0%" />
          <stop stopColor="#D43650" stopOpacity={0.326} offset="29%" />
          <stop stopColor="#D6364F" offset="65.4%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.003 0C7.163 0 0 7.163 0 16.003 0 24.837 7.163 32 16.003 32 24.844 32 32 24.837 32 16.003 32 7.163 24.837 0 16.003 0z"
          fill={`url(#${idA})`}
          fillRule="nonzero"
        />
        <path
          d="M23.197 14.659l-1.95-5.533a1.377 1.377 0 00-1.288-.89h-.006c-.584 0-1.099.35-1.289.884l-2.671 7.651L13.32 9.12c-.2-.536-.715-.89-1.288-.885h-.007a1.359 1.359 0 00-1.288.891l-1.941 5.535h-1.87c-.754 0-1.364.586-1.364 1.308 0 .722.61 1.307 1.363 1.307h.955l-1.096 3.112c-.237.683.142 1.424.854 1.658.14.046.287.07.434.072.57 0 1.099-.345 1.289-.891l2.685-7.618 2.658 7.63c.2.536.715.89 1.288.884.583 0 1.098-.351 1.288-.885l2.658-7.63 2.685 7.625c.238.683 1.01 1.054 1.73.82.711-.228 1.098-.97.854-1.659l-1.09-3.114h.956c.753 0 1.363-.585 1.363-1.307s-.61-1.308-1.363-1.308l-1.877-.006z"
          fill="#FFF"
        />
        <path
          d="M9.73.034L7.926 5.196l1.489 3.887 1.641-4.712-1.3-3.657a1.241 1.241 0 01-.027-.68z"
          fill={`url(#${idB})`}
          transform="translate(8.881 9.238)"
        />
        <path
          d="M1.817 0L0 5.18l1.502 3.908L3.165 4.37 1.843.683a1.245 1.245 0 01-.04-.614L1.816 0z"
          fill={`url(#${idC})`}
          transform="translate(8.881 9.238)"
        />
        <path
          fill={`url(#${idD})`}
          d="M11.158 4.58l-.046-.1z"
          transform="translate(8.881 9.238)"
        />
      </g>
    </svg>
  )
}

export default MeldLogo
