import React from 'react'

export const StakeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M15.364 7.636c-1.31 0-1.964-.581-2.764-1.381C11.582 5.236 10.273 4 7.364 4 4.89 4 3 5.89 3 8.364c0 2.545 2.4 6.545 8.727 6.545 5.382 0 8.728-.145 8.728-3.636 0-2.182-2.619-3.637-5.091-3.637zm-4.946 2.546c-.509.436-1.309.654-2.036.654-.146 0-.364 0-.51-.072-1.09-.219-1.963-1.237-1.963-2.255 0-.945.655-1.964 1.891-1.964 1.09.073 3.2 1.237 3.2 2.546 0 .29-.073.654-.582 1.09z" />
      <path d="M11.727 16.364c-4.436 0-7.2-1.746-8.727-3.855v.946C3 16 5.4 20 11.727 20c5.382 0 8.728-.145 8.728-3.636v-1.6c-1.891 1.6-5.31 1.6-8.728 1.6z" />
    </g>
  </svg>
)
